.page-padding-vertical{
  padding-top: 64px;
  padding-bottom: 64px;
}
.page-header-gap{
  margin-top: 96px;
}
.full-height-page{
  min-height: calc(100vh - 96px);
  padding-top: 64px;
  padding-bottom: 64px;
}
